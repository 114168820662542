<template>
	<div class="index_whole">
		<!--头部-->
		<headers></headers>
		<!-- 中间部分 -->
		<div class="content">
			<router-view></router-view>
		</div>
		<!--尾部-->
		<footers></footers>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	import headers from "./header";//头部
	import footers from "./footer";//尾部
	export default {
		components: {
			headers: headers,
			footers:footers
		},
	    data() {
			return {
				
			}
	    },
		methods: {
			
		},
		created(){
			
		}
   }
</script>

<style>
	.index_whole{
		width: 100%;
	}
	.content{
		min-height: calc(100vh - 385px);
		background-color: #F4F5F9;
		min-width: 1200px;
	}
	.publicTitle{
		height: 68px;
		line-height: 68px;
		background-image: url(images/titlebg.png);
		background-position: center left;
		background-repeat: no-repeat;
		padding-left: 20px;
		font-size: 18px;
		color: #333;
		font-weight: bold;
	}
</style>